<template>
  <div :id="'mappercontainer'+mapContent.name" >
    <div class="mapper-container" id="mappercontainer" :ref="'mappercontainer'+mapContent.name">
      <img
        class="map"
        id="map"
        :width="mapper.width"
        :height="mapper.height"
        :src="mapper.image"
        :usemap="`#${mapContent.name}`"
      />

      <map :name="mapContent.name">
        <area
          v-for="(x, i) in mapContent.areas"
          :key="i"
          :index="i"
          @click="alertMap((mapContent.areas[i].status == 1)?mapContent.areas[i].folio:'Sin Asignar')"
          :alt="`Unidad: ${mapContent.areas[i].folio}`"
          :shape="mapContent.areas[i].shape"
          :data-maphilight="mapContent.areas[i].colorCode"
          :coords="mapContent.areas[i].coords"
          :title="mapContent.areas[i].statusName"
          :data-toggle="(mapContent.areas[i].status == 1)?'modal':''"
          :data-target="(mapContent.areas[i].status == 1)?'#modalUnit':''"
          class="cc_pointer"
        />
      </map>
    </div>

    <Modal/>
    <!-- Alert section
      <main id="alertContent" style="display:none;">
        <div v-if="infoUnit"  class="property-topinfo cc_cursor" style="padding-top:20px;">
          <div class="row justify-content-center" style="padding: 5px;font-size:16px">
            <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                <b > Folio: </b>
                <br>
                <span>{{infoUnit.folio}}</span>
            </div>
            <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                <b > {{$t("pages.development_detail.mapper_alert_form_area")}}: </b>
                <br>
                <span>{{infoUnit.m2c}} m<sup>2</sup></span>
            </div>
            <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                <b > {{$t("pages.development_detail.mapper_alert_form_cost")}}: </b>
                <br>
                <span>{{infoUnit.priceFinance}} </span>
            </div>
            <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                <b > {{$t("pages.development_detail.mapper_alert_form_availability")}}: </b>
                <br>
                <span>{{infoUnit.status}} </span>
            </div>
            <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                <b> {{$t("pages.development_detail.mapper_alert_form_deposit")}}: </b>
                <br>
                <span>{{infoUnit.inicial}} </span>
            </div>

            <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                <b > {{$t("pages.development_detail.mapper_alert_form_monthlypayment")}}: </b>
                <br>
                <span>{{infoUnit.termPrice[`${termSelected}`]}}</span>
            </div>
            <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                <b > {{$t("pages.development_detail.mapper_alert_form_term")}}: </b>
                <br>
                <select class="form-control" id="term" name="term" data-placeholder="Plazo" v-model="termSelected">
                    <option v-for="(term,i) in infoUnit.term" :key="term" :index="i" :value="i">{{infoUnit.term[i]}}</option>
                </select>
            </div>
          </div>
          <div class="container-fluid mt-5">
            <h3>{{$t("pages.development_detail.mapper_alert_contactform_title")}}</h3>
            <hr>
            <div class="row justify-content-center">
              <div class="col-12">
                <ContactForm />
              </div>
            </div>
          </div>

        </div>
      </main-->
  </div>
</template>
<script>
import i18n from '../../i18n/index.js'
import ContactForm from "@/components/layout/ContactForm.vue";
import Modal from '@/components/layout/Modal.vue';
export default {
  components: {
    ContactForm,
    Modal
  },
  props: {
    mapper: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      nameMap:'',
      infoUnit: 0,
      termSelected:"1",
      map:{},
      mapContent:{
        name:'',
        areas:[]
      }
    }
  },
  watch:{
    infoUnit: function () {
      var self = this;
      setTimeout(function(){
        self.createAlert();
      },300)
    }
  },

  mounted(){
    this.map=this.mapper.poly;
    var self=this
    self.configMap();
    setTimeout(function(){
      self.createMaphilight();
      self.resizeMap();
    },500);
  },
  methods: {
    createMaphilight() {
      this.nameMap = 'mappercontainer'+this.mapContent.name;
      let mapContain= document.getElementById(this.nameMap);
      let mapper = $(mapContain).find(".map");
      mapper.maphilight();
    },
    createAlert(){
      var self=this;
      let template = document.getElementById('alertContent').innerHTML//self.$refs.alertContent;
      self.$swal({
        title: `<b>${self.infoUnit.name}</b>`,

        html: `<div><alert-comp>${template}</alert-comp></div>`,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
        confirmButtonText: "Cerrar",
        confirmButtonAriaLabel: "Thumbs up, great!",
        width: "70%",
        font: "1rem",
      });
    },
    alertMap(i) {
      let self = this;
      if (i != "Sin Asignar") {
        $.ajax({
          url: "https://api.immocrm360.com.mx",
          type: "post",
          data: {
            m: "infoUnit",
            folio: i,
          },
          success: function(response) {
            if (response.status == 200) {
              //self.infoUnit=response.data;
              self.$store.dispatch('setActiveUnit',response.data);
            }
          },
          error: function() {
            console.log("falló el envio de datos");
          },
        });
      }else {
        self.$swal({
          title: `<b>${i18n.t("pages.development_detail.mapper_alert_error")}</b>`,
          icon: "error",
          //text:'A esta secci[on] no se le a asignado unidad',
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: "Cerrar",
          confirmButtonAriaLabel: "Thumbs up, great!",
          //width: '80em',
          font: "3rem",
        });
      }
    },
    getInfoMap(map, att) {
      if (map.search(att) != "-1") {
        var newMap = map.split(att + '="');
        var filtMap = newMap[1].split('"');
        return filtMap[0];
      } else {
        filtMap = "Sin Asignar";
        return filtMap;
      }
    },
    configMap(){
      this.mapContent.name=this.getInfoMap(this.map,'name');
      //get all areas of map
      let areas=this.map.split('area');
      //Delete firs element (junk content)
      areas.shift();

      for(let x in areas){
        let contentArea={};
        contentArea.coords=this.getInfoMap(areas[x], 'coords');
        contentArea.folio=this.getInfoMap(areas[x], 'data-id');
        contentArea.shape=this.getInfoMap(areas[x], 'shape');
        contentArea.status=this.mapper.units.find( item => item.propertyid == contentArea.folio ).status;
        switch(contentArea.status){
          case '1':
            contentArea.colorCode = '{"fillColor":"93E945", "strokeColor":"93E945"}';
            contentArea.statusName='Disponible';
            break;
          case '2':
            contentArea.colorCode = '{"fillColor":"FFC848", "strokeColor":"FFC848"}';
            contentArea.statusName='Reservado';
            break;
          default:
            contentArea.colorCode = '{"fillColor":"E94545", "strokeColor":"E94545"}';
            contentArea.statusName='No Disponible';
            break;
        }
        this.mapContent.areas.push(contentArea);
      }
    },
    resizeMap(){
      //let reference = document.getElementById('mappercontainer');
      //console.log(reference);
      var elementContainer = document.getElementById("mappercontainer");
      var dataContainer = elementContainer.getBoundingClientRect();
      var widthContainer = dataContainer.width;
      var elementContent = document.getElementsByClassName('map')[0];
      var dataContent = elementContent.getBoundingClientRect();
      var widthContent = dataContent.width;
      var regularScale=1;
      elementContent.style.transformOrigin=`top left`;

      do {
        regularScale=regularScale-0.001;
        elementContent.style.transform=`scale(${regularScale})`;
        dataContent = elementContent.getBoundingClientRect();
        widthContent = dataContent.width;


        //Adjust container size after scaling map
        elementContainer.style.height=`${dataContent.height}px`;

      } while (widthContainer<=widthContent);

      $('.zoom').hover(function() {
          $(this).addClass('transition');
      }, function() {
          $(this).removeClass('transition');
      });
    }
  },
};
</script>
<style scoped>
area {
  cursor: pointer !important;
}
.mapper-container {
  overflow: hidden;
}


.map{
  transform-origin: top left;
  overflow:none;
}

.zoom {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
}

.transition {
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -o-transform: scale(1.25);
    transform: scale(1.25);
}

</style>
